import React from 'react'

import classNames from 'classnames'
import {
  Body,
  Button,
  CloudinaryImage,
  Spacer,
  TitleLarge,
  TitleSmall,
} from 'ethos-design-system'
import PropTypes from 'prop-types'

import ColoredPaddedContainer from '../global/ColoredPaddedContainer'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../global/Markdown'
import VideoEmbed from '../global/VideoEmbed'
import styles from './MultiColumn.module.scss'
import { IUL_HOMEPAGE_COPY } from './constants'

const DISPLAY_CTA_POSITION = {
  NONE: 'none',
  MIDDLE: 'middle',
  END: 'end',
}

const ALT_BODY_COPY = {
  iul: IUL_HOMEPAGE_COPY,
}

interface ColumnProps {
  groupImage: any
  imageAlt: string | null | undefined
  youtubeId: string
  groupTitle: string
  groupBodyCopy: string
  altGroupBodyCopy?: keyof typeof ALT_BODY_COPY
  displayCta: any
  ctaLabel:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined
  clickthroughUrl: string | undefined
  newTab: any
  additionalCtas: any[]
  ctaStyle: string
}

/**
 * MultiColumn Module on the frontend.
 *
 * @param {object} moduleData - Results of the MultiColumnQuery
 */
const MultiColumn = ({ moduleData }: any) => {
  // 2 & 4 column default large image dimensions
  let PhoneOnlyImageWidth = 327
  let PhoneOnlyImageHeight = 327
  let TabletAndLaptopImageWidth = 400
  let TabletAndLaptopImageHeight = 400
  let DesktopOnlyImageWidth = 560
  let DesktopOnlyImageHeight = 560

  const DisplayImages = moduleData.groupSettings.imageType
  const DisplayHeaders = moduleData.displayHeading
  let SubHeadingType = moduleData.subHeadingType

  const {
    ctaButtonStyle,
    displayCtaPosition,
    heading,
    subHeading,
    enableH1,
    centerColumnContent,
    centerHeaderContent,
    alternateFontStyle,
  } = moduleData
  let { backgroundColor, columns } = moduleData
  if (!backgroundColor) backgroundColor = 'white'

  if (!DisplayHeaders) {
    SubHeadingType = 'hidden'
  }

  const headingElement = enableH1 ? 'h1' : 'div'

  const subHeadingClass = [styles.MultiColumn__Subhead]
  if (centerColumnContent) {
    if (centerHeaderContent) {
      subHeadingClass.push(styles.MultiColumn__SubheadCentered)
    } else {
      subHeadingClass.push(styles.MultiColumn__SubheadLeftAlign)
    }
  }

  if (alternateFontStyle) {
    subHeadingClass.push(styles.MultiColumn__SubheadAlternate)
  }

  const Heading = () => (
    <div
      className={
        centerHeaderContent
          ? styles.MultiColumn__HeadingsCentered
          : styles.MultiColumn__Headings
      }
    >
      {SubHeadingType === 'eyebrow' ? (
        <div className={styles.MultiColumn__Eyebrow}>
          <Body.Regular400>
            <Markdown
              input={subHeading}
              allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
            />
          </Body.Regular400>
          <Spacer.H4 />
        </div>
      ) : (
        <div className={styles.MultiColumn__NoEyebrow} />
      )}
      <TitleLarge.Serif.Book500 element={headingElement}>
        <Markdown
          input={heading}
          allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
        />
      </TitleLarge.Serif.Book500>

      {SubHeadingType === 'subhead' && (
        <div className={subHeadingClass.join(' ')}>
          <Body.Regular400>
            <Markdown
              input={subHeading}
              allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
            />
          </Body.Regular400>
        </div>
      )}
    </div>
  )

  const CtaButton =
    ctaButtonStyle === 'stroked'
      ? Button['Medium']['BlackOutline']
      : Button['Medium']['Black']

  const DisplayBlockCTA_MIDDLE =
    displayCtaPosition === DISPLAY_CTA_POSITION.MIDDLE
  const DisplayBlockCTA_END = displayCtaPosition === DISPLAY_CTA_POSITION.END

  let CountClass = null

  // Determine column count and row class
  if (columns && columns.length >= 2) {
    switch (moduleData.columns.length) {
      case 3:
        CountClass = 'MultiColumn__RowThreeColumns'
        PhoneOnlyImageWidth = 327
        PhoneOnlyImageHeight = 408
        TabletAndLaptopImageWidth = 240
        TabletAndLaptopImageHeight = 300
        DesktopOnlyImageWidth = 320
        DesktopOnlyImageHeight = 400
        break
      case 4:
        CountClass = 'MultiColumn__RowFourColumns'
        break
      default:
        CountClass = 'MultiColumn__RowTwoColumns'
    }
  } else {
    columns = []
  }

  if (DisplayImages === 'medium') {
    PhoneOnlyImageWidth = 240
    PhoneOnlyImageHeight = 140
    TabletAndLaptopImageWidth = 260
    TabletAndLaptopImageHeight = 180
    DesktopOnlyImageWidth = 260
    DesktopOnlyImageHeight = 180
  }

  const imageWidths = [
    PhoneOnlyImageWidth,
    TabletAndLaptopImageWidth,
    TabletAndLaptopImageWidth,
    DesktopOnlyImageWidth,
  ]
  const imageHeights = [
    PhoneOnlyImageHeight,
    TabletAndLaptopImageHeight,
    TabletAndLaptopImageHeight,
    DesktopOnlyImageHeight,
  ]

  const iconClasses = [
    centerColumnContent ? styles.MultiColumn__IconImageCentered : '',
    styles.MultiColumn__IconImage,
  ]

  const titleClass = centerColumnContent ? styles.MultiColumn__CenterTitle : ''

  // Output markup and React elements
  return (
    <ColoredPaddedContainer color={backgroundColor}>
      <div className={styles.MultiColumn__Wrapper}>
        {DisplayHeaders && <Heading />}

        {DisplayBlockCTA_MIDDLE ? (
          <div className={styles.MultiColumn__MiddleCTA}>
            <a
              href={moduleData.clickthroughUrl}
              target={moduleData.newTab ? '_blank' : '_self'}
            >
              <CtaButton>{moduleData.ctaLabel}</CtaButton>
            </a>
          </div>
        ) : (
          <div className={styles.MultiColumn__NoMiddleCTA} />
        )}

        {/* @ts-ignore */}
        <div className={styles[CountClass]}>
          {columns.map((column: ColumnProps, index: number) => (
            <div
              className={styles.MultiColumn__Column}
              key={`column_${column.ctaLabel}_${index}`}
            >
              {DisplayImages === 'small' && (
                <div className={iconClasses.join(' ')}>
                  <CloudinaryImage
                    publicId={column.groupImage}
                    alt={column.imageAlt}
                  />
                </div>
              )}
              {DisplayImages === 'medium' && (
                <div className={styles.MultiColumn__ImageMedium}>
                  <CloudinaryImage
                    publicId={column.groupImage}
                    width={imageWidths}
                    height={imageHeights}
                    alt={column.imageAlt}
                    crop={CloudinaryImage.CROP_METHODS.FIT}
                  />
                </div>
              )}
              {DisplayImages === 'large' && (
                <div>
                  {column.youtubeId ? (
                    <VideoEmbed
                      youtubeId={column.youtubeId}
                      videoTitle={column.imageAlt}
                    />
                  ) : (
                    <CloudinaryImage
                      publicId={column.groupImage}
                      width={imageWidths}
                      height={imageHeights}
                      alt={column.imageAlt}
                    />
                  )}
                </div>
              )}
              {DisplayImages !== 'none' && <Spacer.H16 />}
              <div className={titleClass}>
                {alternateFontStyle ? (
                  <Body.Regular400>
                    <Markdown
                      input={column.groupTitle}
                      allowedMarkdownTypes={
                        MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY
                      }
                    />
                  </Body.Regular400>
                ) : centerColumnContent ? (
                  <TitleSmall.Sans.Medium500>
                    <Markdown
                      input={column.groupTitle}
                      allowedMarkdownTypes={
                        MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY
                      }
                    />
                  </TitleSmall.Sans.Medium500>
                ) : (
                  <TitleSmall.Sans.Regular400>
                    <Markdown
                      input={column.groupTitle}
                      allowedMarkdownTypes={
                        MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY
                      }
                    />
                  </TitleSmall.Sans.Regular400>
                )}
              </div>
              {column.groupBodyCopy && !centerColumnContent && (
                <div className={styles.MultiColumn__BodyCopy}>
                  {column.altGroupBodyCopy &&
                  ALT_BODY_COPY[
                    column.altGroupBodyCopy as keyof typeof ALT_BODY_COPY
                  ] ? (
                    ALT_BODY_COPY[
                      column.altGroupBodyCopy as keyof typeof ALT_BODY_COPY
                    ][index]
                  ) : (
                    <Body.Regular400>
                      <Markdown
                        input={column.groupBodyCopy}
                        allowedMarkdownTypes={
                          MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY
                        }
                      />
                    </Body.Regular400>
                  )}
                </div>
              )}
              {column.displayCta &&
                column.ctaLabel &&
                column.clickthroughUrl && (
                  <div className={styles.MultiColumn__ColumnCTA}>
                    <a
                      href={column.clickthroughUrl}
                      className={classNames(
                        column.ctaStyle === 'filled' &&
                          'mt-5 bg-forest px-6 py-4 text-white'
                      )}
                      target={column.newTab ? '_blank' : '_self'}
                    >
                      <Body.Regular400>
                        <span
                          className={classNames(
                            column.ctaStyle === 'filled' &&
                              'text-white hover:text-white'
                          )}
                        >
                          {column.ctaLabel}
                        </span>
                        {column.ctaStyle !== 'filled' && <span>&#8594;</span>}
                      </Body.Regular400>
                    </a>
                  </div>
                )}
              {column.displayCta && column.additionalCtas && (
                <>
                  {column.additionalCtas.map((cta, index) => (
                    <div
                      className={styles.MultiColumn__ColumnCTA}
                      key={`cta_${cta.ctaLabel}_${index}`}
                    >
                      {cta.clickthroughUrl && cta.ctaLabel && (
                        <a
                          href={cta.clickthroughUrl}
                          target={cta.newTab ? '_blank' : '_self'}
                        >
                          <Body.Regular400>
                            <span>{cta.ctaLabel}</span> &#8594;
                          </Body.Regular400>
                        </a>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </div>

        {/* Bottom with Bottom CTA Button */}
        {DisplayBlockCTA_END ? (
          <div className={styles.MultiColumn__EndCTA}>
            <a href={moduleData.clickthroughUrl}>
              <CtaButton>{moduleData.ctaLabel}</CtaButton>
            </a>
          </div>
        ) : (
          <div className={styles.MultiColumn__NoEndCTA} />
        )}
      </div>
    </ColoredPaddedContainer>
  )
}

MultiColumn.propTypes = {
  moduleData: PropTypes.shape({
    type: PropTypes.string.isRequired,
    heading: PropTypes.string,
    enableH1: PropTypes.bool,
    displayHeading: PropTypes.bool,
    subHeading: PropTypes.string,
    subHeadingType: PropTypes.oneOf(['hidden', 'eyebrow', 'subhead'])
      .isRequired,
    alternateFontStyle: PropTypes.bool,
    groupSettings: PropTypes.shape({
      imageType: PropTypes.oneOf(['large', 'medium', 'small', 'none'])
        .isRequired,
    }).isRequired,
    centerColumnContent: PropTypes.bool,
    centerHeaderContent: PropTypes.bool,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        groupImage: PropTypes.string,
        imageAlt: PropTypes.string,
        groupTitle: PropTypes.string.isRequired,
        groupBodyCopy: PropTypes.string,
        altGroupBodyCopy: PropTypes.string,
        ctaLabel: PropTypes.string,
        displayCta: PropTypes.bool,
        clickthroughUrl: PropTypes.string,
        youtubeId: PropTypes.string,
        newTab: PropTypes.bool,
        additionalCtas: PropTypes.arrayOf(
          PropTypes.shape({
            ctaLabel: PropTypes.string,
            clickthroughUrl: PropTypes.string,
            newTab: PropTypes.bool,
          })
        ),
      })
    ),
    ctaButtonStyle: PropTypes.string,
    displayCtaPosition: PropTypes.string,
    clickthroughUrl: PropTypes.string,
    newTab: PropTypes.bool,
    ctaLabel: PropTypes.string,
    backgroundColor: PropTypes.oneOf(['buttercup', 'moss', 'duckegg', 'white'])
      .isRequired,
    experimentKey: PropTypes.string,
  }),
}

export default MultiColumn
