import React from 'react'

import { Body2, COLORS, Spacer } from 'ethos-design-system'

export const IUL_HOMEPAGE_COPY = [
  <>
    <Body2.Medium500 color={COLORS.GRAY_SECONDARY}>
      Lifelong protection for your family
    </Body2.Medium500>
    <Spacer.H16 />
    <Body2.Regular400 color={COLORS.GRAY_SECONDARY}>
      Ethos IUL is designed to provide life insurance coverage you’ll never
      outlive. As long as certain premium and cash value requirements are met,
      your loved ones are guaranteed to receive a tax-free death benefit
      <sup>1</sup>.
    </Body2.Regular400>
    <Spacer.H16 />
    <Body2.Medium500 color={COLORS.GRAY_SECONDARY}>
      Financial support if major illness strikes
    </Body2.Medium500>
    <Spacer.H16 />
    <Body2.Regular400 color={COLORS.GRAY_SECONDARY}>
      If you’re diagnosed with a qualifying critical, chronic, or terminal
      illness, you can access a portion of your death benefit early for medical
      expenses, your bucket list, or any other purpose.
    </Body2.Regular400>
  </>,
  <>
    <Body2.Medium500 color={COLORS.GRAY_SECONDARY}>
      Tax-advantaged accumulation
    </Body2.Medium500>
    <Spacer.H16 />
    <Body2.Regular400 color={COLORS.GRAY_SECONDARY}>
      Increase cash value through fixed account growth or through index options
      that credit you based on the performance of popular market indexes, up to
      a maximum. Growth is generally tax-free<sup>2</sup>.
    </Body2.Regular400>
    <Spacer.H16 />
    <Body2.Medium500 color={COLORS.GRAY_SECONDARY}>
      Zero downside risk
    </Body2.Medium500>
    <Spacer.H16 />
    <Body2.Regular400 color={COLORS.GRAY_SECONDARY}>
      If an index is down, the index option to which it’s tracked will never go
      below 0%—meaning you’ll avoid all market-based losses.
    </Body2.Regular400>
  </>,
  <>
    <Body2.Medium500 color={COLORS.GRAY_SECONDARY}>
      Liquidity during your working years
    </Body2.Medium500>
    <Spacer.H16 />
    <Body2.Regular400 color={COLORS.GRAY_SECONDARY}>
      Access your policy’s available cash value any time via tax-free policy
      loans and withdrawals<sup>3</sup>. Loans and withdrawals affect long-term
      policy performance.
    </Body2.Regular400>
    <Spacer.H16 />
    <Body2.Medium500 color={COLORS.GRAY_SECONDARY}>
      Retirement income options
    </Body2.Medium500>
    <Spacer.H16 />
    <Body2.Regular400 color={COLORS.GRAY_SECONDARY}>
      Activate the Lifetime Income option for a monthly income that lasts as
      long as you live. Or tap your policy’s cash value for supplemental
      retirement income as needed.
    </Body2.Regular400>
  </>,
]
